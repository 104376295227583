import React from "react";
import { Amplify } from "aws-amplify";
import { BrowserRouter } from "react-router-dom";
import MainPage from "./pages/MainPage";
import { Authenticator, Image, View } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./sam-aws-exports";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers";

Amplify.configure(awsExports);

const formFields = {
  confirmVerifyUser: {
    confirmation_code: {
      labelHidden: false,
      label: "New Label",
      placeholder: "Enter your Confirmation Code:",
      isRequired: false,
    },
  },
};

const LoginLogo = () => {
  const Header = () => (
    <div className="login-logo-wrapper">
      <Image src="/logo.png" alt="Vecto" />
    </div>
  );

  return { Header };
};

export default function App() {
  const components = {
    SignIn: LoginLogo(),
    ResetPassword: LoginLogo(),
  };

  return (
    <div id="app">
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Authenticator class="loginScreen" formFields={formFields} hideSignUp={true} components={components}>
          {({ signOut, user }) => (
            <BrowserRouter>
              <MainPage signOut={signOut} user={user} />
            </BrowserRouter>
          )}
        </Authenticator>
      </LocalizationProvider>
    </div>
  );
}
