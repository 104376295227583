import SearchIcon from "@mui/icons-material/Search";
import { InputBase, InputAdornment } from "@mui/material";

const SearchInput = (props) => {
  const { value, setFilterText } = props;

  return (
    <InputBase
      className="search-input"
      placeholder="SEARCH"
      value={value}
      onChange={(e) => setFilterText(e.target.value)}
      endAdornment={
        <InputAdornment position="end">
          <SearchIcon />
        </InputAdornment>
      }
    />
  );
};
export default SearchInput;
