import moment from "moment";
import { Auth, API } from "aws-amplify";

import { VOUCHER_TYPES, VOUCHER_STATUSES, PROVIDERS, DEFAULT_DATE_FORMAT } from "../config";

const formatDate = (date, formatDef = DEFAULT_DATE_FORMAT) => moment(date).format(formatDef);
const formatUsDollars = (value) => {
  let stringValue = Number(value).toFixed(2).toString();
  let [dollars, cents] = stringValue.split(".");
  dollars = Number(dollars).toLocaleString("en-US");
  return ["$", dollars, ".", cents];
};

const lookupByValue = (array, key) => array.find(({ value }) => value === key) || {};
const lookupVoucherType = (key) => lookupByValue(VOUCHER_TYPES, key);
const lookupVoucherStatus = (key) => lookupByValue(VOUCHER_STATUSES, key);
const lookupProvider = (key) => lookupByValue(PROVIDERS, key);

const getJwt = async () => {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  return accessToken.getJwtToken();
};

const getJwtPayload = async () => {
  const jwt = await getJwt();
  const encodedPayload = jwt.split(".")[1];
  const decodedPayload = window.atob(encodedPayload).toString();
  return JSON.parse(decodedPayload);
};

const getApiAuthHeaders = async () => {
  const jwt = await getJwt();
  const Authorization = `Bearer ${jwt}`;
  return { Authorization };
};

const isUserAdmin = async () => {
  const payload = await getJwtPayload();
  return payload["cognito:groups"].includes("vectoadmin");
};

const getCompanies = async () => {
  const headers = await getApiAuthHeaders();
  const response = await API.get("vectoapi", "/api/companies", { headers });
  const { data } = response;
  return data;
};

const getAdjustersByCompany = async (companyId) => {
  const headers = await getApiAuthHeaders();
  const response = await API.get("vectoapi", `/api/companies/${companyId}/adjusters`, { headers });
  const { data } = response;
  return data;
};

const getAdjuster = async (username) => {
  const headers = await getApiAuthHeaders();
  const response = await API.get("vectoapi", `/api/adjusters/${username}`, { headers });
  const { data } = response;
  return data;
};

export {
  getJwt,
  getJwtPayload,
  getApiAuthHeaders,
  isUserAdmin,
  formatDate,
  formatUsDollars,
  lookupByValue,
  lookupVoucherType,
  lookupVoucherStatus,
  lookupProvider,
  getCompanies,
  getAdjustersByCompany,
  getAdjuster,
};
