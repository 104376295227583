import { useState, useEffect, useCallback } from "react";

import { API, Auth } from "aws-amplify";

import { Grid, Modal, Card, CardHeader, Button } from "@mui/material";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import TextField from "@mui/material/TextField";

async function getApiAuthHeaders() {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const jwtToken = accessToken.getJwtToken();
  const Authorization = `Bearer ${jwtToken}`;
  return { Authorization };
}

const ViewCompanyModal = ({ company, open, setOpen }) => {
  const [users, setUsers] = useState([]);
  const [isEditMode, setEditMode] = useState(false);
  const [username, setUsername] = useState("");

  const getUsers = useCallback(async () => {
    try {
      const headers = await getApiAuthHeaders();
      const response = await API.get("vectoapi", `/api/companies/${company.id}/adjusters`, { headers });
      const { data } = response;
      return data;
    } catch (error) {
      const message = `Unable to fetch adjusters for ${company.id}`;
      alert(message);
      console.error(message, error);
      return [];
    }
  }, [company]);

  const addUserToCompany = useCallback(async () => {
    try {
      const headers = await getApiAuthHeaders();
      const body = { companyId: company.id };
      await API.put("vectoapi", `/api/adjusters/${username}`, {
        headers,
        body,
      });
      const users = await getUsers();
      setUsername("");
      setUsers([...users]);
    } catch (error) {
      const message = `Unable to add ${username} to ${company.id}`;
      console.error(message, error);
      alert(message);
    }
  }, [username, company, setUsers, getUsers]);

  useEffect(() => {
    getUsers().then((users) => setUsers(users));
  }, [getUsers, setUsers]);

  const CloseButton = (
    <Button
      variant="contained"
      onClick={() => {
        setOpen(false);
        setUsers([]);
      }}
    >
      CLOSE
    </Button>
  );
  const SaveButton = (
    <Button
      variant="contained"
      onClick={async (e) => {
        await addUserToCompany();
        setEditMode(false);
      }}
    >
      SAVE
    </Button>
  );
  const CancelButton = (
    <Button
      color="error"
      onClick={() => {
        setEditMode(false);
        setUsername("");
      }}
    >
      CANCEL
    </Button>
  );
  const AddUserButton = (
    <Button variant="outlined" onClick={() => setEditMode(true)}>
      <PersonAddIcon style={{ marginRight: "20px" }} /> ADD USER
    </Button>
  );
  const UsernameInput = (
    <TextField
      required
      value={username}
      onChange={(e) => setUsername(e.target.value)}
      id="username"
      label="Enter username"
      variant="standard"
    />
  );

  return (
    <Modal
      open={open}
      onClose={() => {
        setOpen(false);
        setUsers([]);
      }}
    >
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
          width: "50vw",
        }}
      >
        <CardHeader title="View Company" />

        <Grid container spacing={0}>
          <Grid item xs={5}>
            <p>Company Information</p>
            <Grid container spacing={0}>
              <Grid item xs={4}>
                Name:
              </Grid>
              <Grid item xs={8}>
                {company.name}
              </Grid>
              <Grid item xs={4}>
                Address:
              </Grid>
              <Grid item xs={8}>
                {company.address}
                <br />
                {company.city}, {company.state} {company.postalCode}
              </Grid>
              <Grid item xs={4}>
                Phone:
              </Grid>
              <Grid item xs={8}>
                (555) 867-5309
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={7}>
            <p>Associated Users</p>
            <Grid container spacing={0}>
              {users
                ? users.map((user) => {
                    const { username } = user;
                    return (
                      <Grid key={username} item xs={6}>
                        Username: {username}
                      </Grid>
                    );
                  })
                : null}
            </Grid>
          </Grid>

          <Grid item xs={12} style={{ marginTop: "50px" }}>
            {isEditMode ? UsernameInput : AddUserButton}
          </Grid>
          <Grid item xs={12} style={{ textAlign: "right" }}>
            {isEditMode ? (
              <>
                {CancelButton}
                {SaveButton}
              </>
            ) : (
              CloseButton
            )}
          </Grid>
        </Grid>
      </Card>
    </Modal>
  );
};

export default ViewCompanyModal;
