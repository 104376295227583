import React, { useState, useEffect, useCallback } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Typography } from "@mui/material";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import SearchIcon from "@mui/icons-material/Search";
import InputBase from "@mui/material/InputBase";

import AddCompanyModal from "./AddCompanyModal";
import { API, Auth } from "aws-amplify";

import ViewCompanyModal from "./ViewCompanyModal";
import ViewCompanyButton from "./ViewCompanyButton";

async function getApiAuthHeaders() {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const jwtToken = accessToken.getJwtToken();
  const Authorization = `Bearer ${jwtToken}`;
  return { Authorization };
}

const CompanyTable = () => {
  const [columns] = useState([
    { field: "name", headerName: "Customer", flex: 1 },
    {
      field: "address",
      headerName: "Address",
      flex: 1,
      renderCell: (params) => {
        const { row: company } = params;
        const { address, city, state, postalCode } = company;
        return `${address}, ${city}, ${state} ${postalCode}`;
      },
    },

    {
      headerName: "Actions",
      field: "id",
      flex: 1,
      renderCell: (params) => {
        const { row: company } = params;
        return (
          <ViewCompanyButton
            company={company}
            setTargetCompany={setTargetCompany}
            setViewCompanyOpen={setViewCompanyOpen}
          />
        );
      },
    },
  ]);

  const [filterText, setFilterText] = useState("");

  const [filteredRows, setFilteredRows] = useState([]);

  const [addCompanyOpen, setAddCompanyOpen] = useState(false);

  const [companies, setCompanies] = useState([]);

  const [targetCompany, setTargetCompany] = useState({});
  const [viewCompanyOpen, setViewCompanyOpen] = useState(false);

  const getCompanies = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const { data } = await API.get("vectoapi", "/api/companies", { headers });
    return data;
  }, []);

  useEffect(() => {
    getCompanies().then(setCompanies);
  }, [getCompanies]);

  const getFilteredData = useCallback(() => {
    if (!filterText) return companies;

    const lowerFilterText = filterText.toLowerCase();

    return companies.filter((company) => {
      let hasMatch = false;
      for (const key in company) {
        let value = company[key] || "";
        if (value.toLowerCase) {
          value = value.toLowerCase();
          if (!hasMatch && value.startsWith(lowerFilterText)) hasMatch = true;
        }
      }
      return hasMatch;
    });
  }, [companies, filterText]);

  useEffect(() => {
    const data = getFilteredData();
    setFilteredRows(data);
  }, [getFilteredData]);

  return (
    <div>
      <ViewCompanyModal company={targetCompany} open={viewCompanyOpen} setOpen={setViewCompanyOpen} />
      <AddCompanyModal
        open={addCompanyOpen}
        setOpen={setAddCompanyOpen}
        getCompanies={getCompanies}
        setCompanies={setCompanies}
      />
      {filteredRows ? (
        <>
          <AppBar position="static" style={{ marginTop: 10, background: "transparent", boxShadow: "none" }}>
            <Toolbar style={{ display: "flex", alignItems: "center" }}>
              <Typography
                variant="h4"
                style={{ flex: 1, fontWeight: "bold", marginLeft: "10%", marginTop: 20, color: "black" }}
              >
                Companies
              </Typography>

              <SearchIcon style={{ marginLeft: 30, color: "black", marginBottom: -12 }} />
              <InputBase
                style={{ marginBottom: -12 }}
                placeholder=" search"
                value={filterText}
                onChange={(e) => setFilterText(e.target.value)}
              />
              <Button
                onClick={() => setAddCompanyOpen(true)}
                variant="contained"
                style={{
                  backgroundColor: "white",
                  color: "#2F75A8",
                  paddingLeft: 30,
                  paddingRight: 30,
                  marginRight: 100,
                  marginLeft: -50,
                }}
              >
                ADD COMPANY
              </Button>
            </Toolbar>
          </AppBar>

          <Card style={{ marginRight: "10%", marginLeft: "10%" }}>
            <DataGrid
              disableSelectionOnClick
              style={{ height: "70vh", width: "100%" }}
              rows={filteredRows}
              columns={columns}
              rowHeight={35}
              pageSize={50}
            />
          </Card>
        </>
      ) : null}
    </div>
  );
};

export default CompanyTable;
