import React, { useEffect } from "react";
import CompanyTable from "../components/Companies/CompanyTable";

import { isUserAdmin } from "../utils";

const CompanyDashboard = () => {
  useEffect(() => {
    isUserAdmin().then((isAdmin) => {
      if (!isAdmin) window.location = "/";
    });
  }, []);
  return <CompanyTable />;
};

export default CompanyDashboard;
