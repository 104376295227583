import React from "react";
import { Routes, Route } from "react-router-dom";
import Header from "../components/Header";
import VouchersPage from "./Vouchers.js";
import CompaniesPage from "./Companies.js";

const MainPage = ({ signOut, user }) => {
  return (
    <div className="main-page">
      <Header signOut={signOut} user={user} />
      <Routes>
        <Route path="/" element={<VouchersPage user={user} />} />
        <Route path="/companies" element={<CompaniesPage />} />
      </Routes>
      <footer id="footer">
        © 2022 <span className="brand-name">Vecto</span> All Rights Reserved
      </footer>
    </div>
  );
};

export default MainPage;
