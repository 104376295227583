import React, { useCallback, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { API } from "aws-amplify";
import { Box, AppBar, Toolbar, MenuItem, MenuList } from "@mui/material";

import LogoutButton from "./LogoutButton";
import Greeting from "./Greeting";

import { getApiAuthHeaders, isUserAdmin } from "../../utils";

const Header = ({ signOut, user }) => {
  const [isAdmin, setAdmin] = useState(false);
  const [displayName, setDisplayName] = useState("");

  const getDisplayName = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const { username } = user;
    const response = await API.get("vectoapi", `/api/users/${username}`, { headers });
    const { data } = response;
    const { firstName, lastName } = data;
    return [firstName, lastName].join(" ");
  }, [user]);

  useEffect(() => {
    isUserAdmin().then(setAdmin);
    getDisplayName().then(setDisplayName);
  }, [getDisplayName]);

  const Logo = <img id="logo" src="/logo.png" alt="LOGO" />;

  const VouchersLink = (
    <MenuItem component={Link} to="/" className="voucher-link">
      Vouchers
    </MenuItem>
  );

  const CompaniesLink = (
    <MenuItem component={Link} to="/companies" className="company-link">
      Companies
    </MenuItem>
  );

  const AdminLinks = (
    <MenuList className="header-admin-links">
      {VouchersLink}
      {CompaniesLink}
    </MenuList>
  );

  const MenuArea = (
    <Toolbar className="header-menu">
      {Logo}
      {isAdmin && AdminLinks}
      <MenuList>
        <LogoutButton signOut={signOut} />
      </MenuList>
    </Toolbar>
  );

  return (
    <Box>
      <AppBar position="sticky">
        {MenuArea}
        <Greeting displayName={displayName} />
      </AppBar>
    </Box>
  );
};

export default Header;
