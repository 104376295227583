import { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";
import { Grid, Modal, Card, Button, TextField, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { v4 as uuid } from "uuid";

import { VOUCHER_TYPES, VOUCHER_STATUSES, PROVIDERS } from "../../config";

import {
  formatDate,
  formatUsDollars,
  lookupVoucherType,
  lookupVoucherStatus,
  lookupProvider,
  getApiAuthHeaders,
  isUserAdmin,
} from "../../utils";

const ViewVoucherModal = ({ voucher, setVoucher, setVouchers, getVouchers, open, setOpen }) => {
  const [claimId, setClaimId] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [adjusterUsername, setAdjusterUsername] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [adjusterFullName, setAdjusterFullName] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [provider, setProvider] = useState("");
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [status, setStatus] = useState("");

  const [isAdmin, setAdmin] = useState(false);
  const [company, setCompany] = useState({});
  const [companies, setCompanies] = useState([]);
  const [adjusters, setAdjusters] = useState([]);
  const [rideShareCredits, setRideShareCredits] = useState([]);

  const [voucherEditMode, setVoucherEditMode] = useState(false);
  const [voucherEditEndDateMode, setVoucherEditEndDateMode] = useState(false);

  const [creditId, setCreditId] = useState("");
  const [creditCode, setCreditCode] = useState("");
  const [creditProvider, setCreditProvider] = useState("");
  const [creditStart, setCreditStart] = useState(null);
  const [creditEnd, setCreditEnd] = useState(null);

  const resetCreditState = useCallback(() => {
    setCreditId("");
    setCreditCode("");
    setCreditProvider("");
    setCreditStart(null);
    setCreditEnd(null);
  }, [setCreditId, setCreditCode, setCreditProvider, setCreditStart, setCreditEnd]);

  const saveVoucher = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const body = {
      status,
      claimId,
      companyId,
      adjusterUsername,
      firstName,
      lastName,
      email,
      phone,
      adjusterFullName,
      provider,
      type,
      amount,
      startDate,
      endDate,
    };
    const { id } = voucher;
    const response = await API.put("vectoapi", `api/vouchers/${id}`, { headers, body });
    const { data } = response;
    return data;
  }, [
    claimId,
    companyId,
    adjusterUsername,
    firstName,
    lastName,
    email,
    phone,
    adjusterFullName,
    startDate,
    endDate,
    provider,
    type,
    amount,
    status,
    voucher,
  ]);

  const saveCredit = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const body = {
      voucherId: voucher.id,
      code: creditCode,
      provider: creditProvider,
      startDate: creditStart,
      endDate: creditEnd,
    };
    const response = await API.put("vectoapi", `api/rideShareCredits/${creditId}`, { headers, body });
    const { data } = response;
    return data;
  }, [voucher, creditId, creditCode, creditProvider, creditStart, creditEnd]);

  const deleteCredit = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const response = await API.del("vectoapi", `api/rideShareCredits/${creditId}`, { headers });
    const { data } = response;
    return data;
  }, [creditId]);

  const deleteVoucher = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const { id } = voucher;
    const response = await API.del("vectoapi", `api/vouchers/${id}`, { headers });
    const { data } = response;
    return data;
  }, [voucher]);

  const syncStateWithVoucher = useCallback(() => {
    setFirstName(voucher.firstName);
    setLastName(voucher.lastName);
    setEmail(voucher.email);
    setPhone(voucher.phone);
    setAdjusterFullName(voucher.adjusterFullName);
    setClaimId(voucher.claimId);
    setStartDate(voucher.startDate);
    setEndDate(voucher.endDate);
    setProvider(voucher.provider);
    setType(voucher.type);
    setAmount(voucher.amount);
    setAdjusterUsername(voucher.adjusterUsername);
    setCompanyId(voucher.companyId);
    setStatus(voucher.status);
  }, [voucher]);

  const syncStateWithRideShareCredit = useCallback(() => {
    const credit = rideShareCredits.find((credit) => credit.id === creditId);
    if (!credit) {
      resetCreditState();
      return;
    }

    const { code, provider, startDate, endDate } = credit || {};
    setCreditCode(code);
    setCreditProvider(provider);
    setCreditStart(startDate);
    setCreditEnd(endDate);
  }, [creditId, rideShareCredits, resetCreditState]);

  const getCompanies = useCallback(async () => {
    if (!isAdmin) return [];
    const headers = await getApiAuthHeaders();
    const response = await API.get("vectoapi", "api/companies", { headers });
    const { data } = response;
    return data;
  }, [isAdmin]);

  const getCompany = useCallback(async () => {
    if (!companyId) return {};
    const headers = await getApiAuthHeaders();
    const response = await API.get("vectoapi", `api/companies/${companyId}`, { headers });
    const { data } = response;
    return data;
  }, [companyId]);

  const getAdjusters = useCallback(async () => {
    if (!companyId) return [];
    const headers = await getApiAuthHeaders();
    const response = await API.get("vectoapi", `api/companies/${companyId}/adjusters`, { headers });
    const { data } = response;
    return data;
  }, [companyId]);

  const getRideShareCredits = useCallback(async () => {
    if (!open) return [];
    const { id } = voucher;
    const headers = await getApiAuthHeaders();
    const response = await API.get("vectoapi", `api/vouchers/${id}/rideShareCredits`, { headers });
    const { data } = response;
    return data;
  }, [voucher, open]);

  useEffect(() => {
    syncStateWithVoucher();
  }, [syncStateWithVoucher, voucherEditMode, open]);

  useEffect(() => {
    syncStateWithRideShareCredit();
  }, [syncStateWithRideShareCredit]);

  useEffect(() => isUserAdmin().then(setAdmin), []);

  useEffect(() => getCompanies().then(setCompanies), [getCompanies]);

  useEffect(() => {
    getCompany().then(setCompany);
    getAdjusters().then(setAdjusters);
  }, [getCompany, getAdjusters]);

  useEffect(() => {
    setCreditId("");
    getRideShareCredits().then(setRideShareCredits);
  }, [getRideShareCredits]);

  const updateVouchersTable = useCallback(async () => {
    const vouchers = await getVouchers();
    setVouchers(vouchers);
  }, [getVouchers, setVouchers]);

  const saveAndSyncVoucher = useCallback(async () => {
    const updatedVoucher = await saveVoucher();
    setVoucher(updatedVoucher);
  }, [saveVoucher, setVoucher]);

  const submitVoucherHandler = useCallback(async () => {
    try {
      await saveAndSyncVoucher();
      await updateVouchersTable();
    } catch (err) {
      window.alert(err.response.data.message);
    } finally {
      setVoucherEditMode(false);
      setVoucherEditEndDateMode(false);
    }
  }, [saveAndSyncVoucher, updateVouchersTable, setVoucherEditMode, setVoucherEditEndDateMode]);

  const SubmitVoucherButton = (
    <Button variant="contained" onClick={submitVoucherHandler} className="voucher-view-action-button-contained">
      SUBMIT
    </Button>
  );

  const cancelVoucherEditHandler = () => {
    setVoucherEditMode(false);
    setVoucherEditEndDateMode(false);
  };

  const CancelVoucherButton = (
    <Button color="error" onClick={cancelVoucherEditHandler} className="voucher-view-action-button">
      CANCEL
    </Button>
  );

  const isPending = status === "PENDING";
  const canEditVoucher = !creditId && (isAdmin || isPending);

  const isActive = status === "ACTIVE";
  const canEditVoucherEndDate = !isAdmin && isActive;

  const deleteVoucherHandler = useCallback(async () => {
    const confirmDelete = window.confirm("Are you sure you wish to delete this voucher?");
    if (!confirmDelete) return;
    await deleteVoucher();
    const vouchers = await getVouchers();
    setVouchers(vouchers);
    setOpen(false);
  }, [deleteVoucher, getVouchers, setVouchers, setOpen]);

  const DeleteVoucherButton = canEditVoucher && (
    <Button color="error" onClick={deleteVoucherHandler} className="voucher-view-action-button">
      DELETE
    </Button>
  );

  const closeVoucherHandler = () => {
    setOpen(false);
  };

  const CloseButton = (
    <Button variant="contained" onClick={closeVoucherHandler} className="voucher-view-action-button-contained">
      CLOSE
    </Button>
  );

  const TextFieldFactory = (params) => {
    const { id, label, state, updateState } = params;

    return (
      <Grid item xs={5}>
        <FormControl fullWidth>
          <TextField
            value={state}
            onChange={(e) => updateState(e.target.value)}
            key={id}
            id={id}
            label={label}
            variant="standard"
          />
        </FormControl>
      </Grid>
    );
  };

  const DatePickerFactory = (params) => {
    const { id, label, state, updateState } = params;

    return (
      <FormControl fullWidth>
        <DatePicker
          label={label}
          key={id}
          id={id}
          value={state || null}
          onChange={updateState}
          variant="standard"
          renderInput={(renderParams) => <TextField style={{ marginLeft: 5 }} variant="standard" {...renderParams} />}
        />
      </FormControl>
    );
  };

  const DisplayFieldFactory = (params) => {
    const { label, state } = params;
    return (
      <>
        <Grid item xs={2}>
          {label}
        </Grid>
        <Grid item xs={3}>
          {state}
        </Grid>
      </>
    );
  };

  const FirstNameInput = TextFieldFactory({
    state: firstName,
    updateState: setFirstName,
    id: "first-name",
    label: "First Name",
  });

  const LastNameInput = TextFieldFactory({
    state: lastName,
    updateState: setLastName,
    id: "last-name",
    label: "Last Name",
  });

  const NameInputs = (
    <>
      {FirstNameInput}
      {LastNameInput}
    </>
  );

  const NameDisplay = DisplayFieldFactory({
    state: `${lastName}, ${firstName}`,
    label: "Name:",
  });

  const Name = voucherEditMode ? NameInputs : NameDisplay;

  const EmailInput = TextFieldFactory({
    state: email,
    updateState: setEmail,
    id: "email",
    label: "Email",
  });

  const EmailDisplay = DisplayFieldFactory({
    state: email,
    label: "Email:",
  });

  const Email = voucherEditMode ? EmailInput : EmailDisplay;

  const PhoneInput = TextFieldFactory({
    state: phone,
    updateState: setPhone,
    id: "phone",
    label: "Phone",
  });

  const PhoneDisplay = DisplayFieldFactory({
    state: phone,
    label: "Phone:",
  });

  const Phone = voucherEditMode ? PhoneInput : PhoneDisplay;

  const AdjusterFullNameInput = TextFieldFactory({
    state: adjusterFullName,
    updateState: setAdjusterFullName,
    id: "adjuster-full-name",
    label: "Adjuster",
  });

  const AdjusterFullNameDisplay = DisplayFieldFactory({
    state: adjusterFullName,
    label: "Adjuster:",
  });
  const AdjusterFullName = voucherEditMode ? AdjusterFullNameInput : AdjusterFullNameDisplay;

  const ClaimInput = TextFieldFactory({
    state: claimId,
    updateState: setClaimId,
    id: "claim-id",
    label: "Claim Number",
  });
  const ClaimDisplay = DisplayFieldFactory({ label: "Claim Number:", state: claimId });
  const ClaimId = voucherEditMode ? ClaimInput : ClaimDisplay;

  const StartInput = DatePickerFactory({
    id: "start-date",
    label: "Start Date",
    state: startDate,
    updateState: (value) => setStartDate(value),
  });
  const StartDisplay = DisplayFieldFactory({ label: "Start Date:", state: formatDate(startDate) });
  const Start = voucherEditMode ? StartInput : StartDisplay;

  const EndInput = DatePickerFactory({
    id: "end-date",
    label: "End Date",
    state: endDate,
    updateState: (value) => setEndDate(value),
  });
  const EndDisplay = DisplayFieldFactory({ label: "End Date:", state: formatDate(endDate) });
  const End = voucherEditMode || voucherEditEndDateMode ? EndInput : EndDisplay;

  const CompanySelect = (
    <FormControl fullWidth>
      <InputLabel id="company-label">Company</InputLabel>
      <Select
        labelId="company-label"
        id="companyId"
        value={companyId}
        label="Company"
        variant="standard"
        onChange={(e) => {
          setCompanyId(e.target.value);
          setCompany({});
          setAdjusterUsername("");
        }}
      >
        {companies.map((company) => {
          return (
            <MenuItem key={company.id} value={company.id}>
              {company.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const AdjusterSelect = (
    <FormControl fullWidth>
      <InputLabel id="adjuster-label">Adjuster</InputLabel>
      <Select
        labelId="adjuster-label"
        id="adjusterUsername"
        value={adjusterUsername}
        label="Adjuster"
        variant="standard"
        onChange={(e) => setAdjusterUsername(e.target.value)}
      >
        {adjusters.map((adjuster) => {
          return (
            <MenuItem key={adjuster.username} value={adjuster.username}>
              {adjuster.username}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const VoucherProviderSelect = (
    <FormControl fullWidth>
      <InputLabel className="select-label" id="provider-label">
        Provider
      </InputLabel>
      <Select
        labelId="provider-label"
        id="provider"
        value={provider}
        label="Provider"
        variant="standard"
        style={{ marginLeft: 5, paddingTop: 8, paddingLeft: 4 }}
        onChange={(e) => setProvider(e.target.value)}
      >
        {PROVIDERS.map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const ProviderDisplay = DisplayFieldFactory({ state: lookupProvider(provider).label, label: "Provider:" });

  const Provider = voucherEditMode ? VoucherProviderSelect : ProviderDisplay;

  const TypeSelect = (
    <FormControl fullWidth>
      <InputLabel className="select-label" id="type-label">
        Voucher Type
      </InputLabel>
      <Select
        labelId="type-label"
        id="type"
        value={type}
        label="Voucher Type"
        variant="standard"
        style={{ marginLeft: 5, paddingTop: 8, paddingLeft: 4 }}
        onChange={(e) => setType(e.target.value)}
      >
        {VOUCHER_TYPES.map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const TypeDisplay = DisplayFieldFactory({ state: lookupVoucherType(type).label, label: "Voucher Type:" });

  const Type = voucherEditMode ? TypeSelect : TypeDisplay;

  const AmountInput = TextFieldFactory({
    state: amount,
    updateState: setAmount,
    id: "amount",
    label: "Amount",
  });

  const AmountDisplay = DisplayFieldFactory({ state: formatUsDollars(amount), label: "Amount:" });

  const Amount = voucherEditMode ? AmountInput : AmountDisplay;

  const StatusSelect = (
    <FormControl fullWidth>
      <InputLabel id="status-label">Voucher Status</InputLabel>
      <Select
        labelId="status-label"
        id="status"
        value={status}
        label="Voucher Status"
        variant="standard"
        onChange={(e) => setStatus(e.target.value)}
      >
        {VOUCHER_STATUSES.map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const { label, className } = lookupVoucherStatus(status);
  const StatusLabel = <span className={className}>{label || status}</span>;

  const StatusDisplay = DisplayFieldFactory({
    state: StatusLabel,
    label: "Status:",
  });

  const Status = voucherEditMode && isAdmin ? StatusSelect : !voucherEditMode ? StatusDisplay : null;

  const CodeInput = TextFieldFactory({
    state: creditCode,
    updateState: setCreditCode,
    id: "credit-code",
    label: "Code",
  });

  const ProviderSelect = (
    <FormControl fullWidth>
      <InputLabel className="select-label" id="provider-label">
        Provider
      </InputLabel>
      <Select
        labelId="provider-label"
        id="provider"
        value={creditProvider}
        label="Provider"
        variant="standard"
        onChange={(e) => setCreditProvider(e.target.value)}
      >
        {PROVIDERS.map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const CreditStartInput = DatePickerFactory({
    id: "credit-start",
    label: "Start Date",
    state: creditStart,
    updateState: setCreditStart,
  });

  const CreditEndInput = DatePickerFactory({
    id: "credit-end",
    label: "End Date",
    state: creditEnd,
    updateState: setCreditEnd,
  });

  const editVoucherHandler = () => setVoucherEditMode(true);
  const VoucherEditButton = canEditVoucher && (
    <Button variant="outlined" className="voucher-view-action-button" onClick={editVoucherHandler}>
      EDIT
    </Button>
  );

  const editVoucherEndDateHandler = () => setVoucherEditEndDateMode(true);
  const VoucherEditEndDateButton = canEditVoucherEndDate && (
    <Button variant="outlined" className="voucher-view-action-button" onClick={editVoucherEndDateHandler}>
      EXTEND
    </Button>
  );

  const EditTitle = <div className="voucher-view-card-header">Edit Voucher Information</div>;
  const ViewTitle = <div className="voucher-view-card-header">Voucher Information</div>;
  const Title = voucherEditMode ? EditTitle : ViewTitle;

  const DefaultActionButtons = (
    <>
      {DeleteVoucherButton}
      {VoucherEditButton}
      {VoucherEditEndDateButton}
      {CloseButton}
    </>
  );

  const EditVoucherActionButtons = (
    <>
      {CancelVoucherButton}
      {SubmitVoucherButton}
    </>
  );

  const cancelCreditEditHandler = useCallback(async () => {
    const credits = await getRideShareCredits();
    setRideShareCredits(credits);
    setCreditId("");
  }, [getRideShareCredits, setRideShareCredits, setCreditId]);
  const CancelCreditButton = (
    <Button color="error" onClick={cancelCreditEditHandler}>
      CANCEL
    </Button>
  );
  const submitCreditHandler = useCallback(async () => {
    await saveCredit();
    const credits = await getRideShareCredits();
    setRideShareCredits(credits);
    setCreditId("");
  }, [saveCredit, getRideShareCredits, setRideShareCredits, setCreditId]);
  const SubmitCreditButton = (
    <Button variant="contained" onClick={submitCreditHandler}>
      SUBMIT
    </Button>
  );

  const EditCreditActionButtons = (
    <>
      {CancelCreditButton}
      {SubmitCreditButton}
    </>
  );

  const EditActionButtons = creditId ? EditCreditActionButtons : EditVoucherActionButtons;

  const ActionButtons =
    creditId || voucherEditMode || voucherEditEndDateMode ? EditActionButtons : DefaultActionButtons;

  const deleteCreditHandler = useCallback(async () => {
    await deleteCredit();
    const credits = await getRideShareCredits();
    setRideShareCredits(credits);
    setCreditId("");
  }, [deleteCredit, getRideShareCredits, setRideShareCredits, setCreditId]);

  const RideShareRows = rideShareCredits.map((credit) => {
    const { id } = credit;

    const editCreditHandler = () => setCreditId(id);

    const canEditCredits = isAdmin && !creditId;

    const EditCreditButton = canEditCredits && (
      <Button onClick={editCreditHandler}>
        <EditIcon />
      </Button>
    );

    const isNotNew = !!credit.code;
    const editingThisCredit = creditId === id;
    const canDeleteCredit = isNotNew && editingThisCredit;

    const DeleteCreditButton = canDeleteCredit && (
      <Button color="error" variant="contained" style={{ height: "100%" }} onClick={deleteCreditHandler}>
        <DeleteIcon />
      </Button>
    );

    const Code = editingThisCredit ? CodeInput : credit.code;
    const Provider = editingThisCredit ? ProviderSelect : lookupProvider(credit.provider).label;
    const Start = editingThisCredit ? CreditStartInput : formatDate(credit.startDate);
    const End = editingThisCredit ? CreditEndInput : formatDate(credit.endDate);
    const ActionButton = creditId ? DeleteCreditButton : EditCreditButton;

    return (
      <Grid key={id} container spacing={2}>
        <Grid item xs={2}>
          {Code}
        </Grid>
        <Grid item xs={3}>
          {Provider}
        </Grid>
        {!creditId ? (
          <Grid item xs={4}>
            {Start} - {End}
          </Grid>
        ) : (
          <>
            <Grid item xs={3}>
              {Start}
            </Grid>
            <Grid item xs={3}>
              {End}
            </Grid>
          </>
        )}
        <Grid item xs={1}>
          {ActionButton}
        </Grid>
      </Grid>
    );
  });

  const canAddCredits = isAdmin;

  const addNewCreditHandler = () => {
    const id = uuid();
    rideShareCredits.push({ id });
    setRideShareCredits([...rideShareCredits]);
    setCreditId(id);
  };

  const AddRideShareCreditButton = canAddCredits && (
    <Button disabled={!!creditId} onClick={addNewCreditHandler}>
      <EditIcon /> Add Rideshare Credit
    </Button>
  );

  const RideShareCreditTitle = !!rideShareCredits.length && (
    <div className="voucher-view-card-sub-header">Rideshare Credits</div>
  );

  const RideShareCredits = (
    <Grid item xs={2}>
      {RideShareCreditTitle}
      {RideShareRows}
      {AddRideShareCreditButton}
    </Grid>
  );

  const VoucherInfo = (
    <Grid item xs={1}>
      <div className="voucher-view-card-sub-header">Voucher Information</div>
      <Grid container spacing={1} columns={5}>
        {isAdmin && (
          <>
            <Grid item xs={2}>
              Company:
            </Grid>
            <Grid item xs={3}>
              {voucherEditMode ? CompanySelect : company.name}
            </Grid>
            <Grid item xs={2}>
              Created by:
            </Grid>
            <Grid item xs={3}>
              {voucherEditMode ? AdjusterSelect : adjusterUsername}
            </Grid>
          </>
        )}
        {Status}
        {ClaimId}
        {AdjusterFullName}
        {Provider}
        {Type}
        {Amount}
        {Start}

        {End}
      </Grid>
    </Grid>
  );

  const ClientInfo = (
    <Grid item xs={1}>
      <div className="voucher-view-card-sub-header">Client Information</div>
      <Grid container spacing={1} columns={5}>
        {Name}
        {Email}
        {Phone}
      </Grid>
    </Grid>
  );
  const VoucherViewActionButtonsRow = (
    <Grid item xs={2} style={{ textAlign: "Right" }}>
      {ActionButtons}
    </Grid>
  );

  const modalCloseHandler = useCallback(async () => {
    if (voucherEditMode) {
      setVoucherEditMode(false);
      return;
    }

    if (voucherEditEndDateMode) {
      setVoucherEditEndDateMode(false);
      return;
    }

    if (creditId) {
      const credits = await getRideShareCredits();
      setRideShareCredits(credits);
      setCreditId("");
      return;
    }

    setOpen(false);
  }, [
    setVoucherEditMode,
    setVoucherEditEndDateMode,
    getRideShareCredits,
    setRideShareCredits,
    setCreditId,
    setOpen,
    creditId,
    voucherEditMode,
    voucherEditEndDateMode,
  ]);

  return (
    <Modal open={open} onClose={modalCloseHandler}>
      <Card className="voucher-view-card">
        {Title}
        <Grid container spacing={3} columns={2}>
          {ClientInfo}
          {VoucherInfo}
          {!voucherEditMode && RideShareCredits}
          {VoucherViewActionButtonsRow}
        </Grid>
      </Card>
    </Modal>
  );
};

export default ViewVoucherModal;
