import { Modal, Card, Button } from "@mui/material";

const MessageModal = (props) => {
  const { open, setOpen, title, message } = props;

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Card className="message-card">
        <div className="message-card-header">{title}</div>
        <div className="message-card-text">{message}</div>
        <Button variant="contained" className="message-card-action-button" onClick={() => setOpen(false)}>
          OKAY
        </Button>
      </Card>
    </Modal>
  );
};

export default MessageModal;
