import React, { useState, useEffect, useCallback } from "react";
import { API } from "aws-amplify";

// import components
import PageHeader from "../PageHeader";
import SearchInput from "../SearchInput";
import CreateButton from "../CreateButton";
import TableTopper from "../TableTopper";
import Table from "../Table";

import CreateVoucherModal from "./CreateVoucherModal";
import ViewVoucherModal from "./ViewVoucherModal";

// import utils
import { formatDate, formatUsDollars, lookupVoucherStatus, lookupVoucherType, getApiAuthHeaders } from "../../utils";

const VoucherTable = (props) => {
  const { user } = props;

  const [filterText, setFilterText] = useState("");
  const [displayData, setDisplayData] = useState([]);

  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [viewModalOpen, setViewModalOpen] = useState(false);

  const [vouchers, setVouchers] = useState([]);
  const [voucher, setVoucher] = useState({});

  const getVouchers = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const { data } = await API.get("vectoapi", "/api/vouchers", { headers });
    return data;
  }, []);

  useEffect(() => {
    getVouchers().then(setVouchers);
  }, [getVouchers]);

  const getFilteredData = useCallback(() => {
    if (!filterText) return vouchers;

    const lowerFilterText = filterText.toLowerCase();

    return vouchers.filter((voucher) => {
      let hasMatch = false;
      for (const key in voucher) {
        let value = voucher[key] || "";
        if (value.toLowerCase) {
          value = value.toLowerCase();
          if (!hasMatch && value.startsWith(lowerFilterText)) hasMatch = true;
        }
      }
      return hasMatch;
    });
  }, [vouchers, filterText]);

  useEffect(() => {
    const data = getFilteredData();
    setDisplayData(data);
  }, [getFilteredData]);

  const columns = [
    { field: "claimId", headerName: "Claim", headerClassName: "vecto-column-header", flex: 1 },
    { field: "adjusterFullName", headerName: "Adjuster", headerClassName: "vecto-column-header", flex: 2 },
    {
      field: "lastName",
      headerName: "Client",
      headerClassName: "vecto-column-header",
      flex: 2,
      renderCell: ({ row }) => (
        <span className="client-name">
          {row.lastName}, {row.firstName}
        </span>
      ),
    },
    {
      field: "type",
      headerName: "Type",
      headerClassName: "vecto-column-header",
      flex: 1,
      renderCell: ({ row }) => {
        const { type } = row;
        const { label } = lookupVoucherType(type);
        return label || type;
      },
    },
    {
      field: "amount",
      headerName: "Amount",
      headerClassName: "vecto-column-header",
      flex: 1,
      renderCell: ({ row }) => formatUsDollars(row.amount),
    },
    {
      field: "startDate",
      headerName: "Start Date",
      headerClassName: "vecto-column-header",
      flex: 1,
      renderCell: ({ row }) => formatDate(row.startDate),
    },
    {
      field: "endDate",
      headerName: "End Date",
      headerClassName: "vecto-column-header",
      flex: 1,
      renderCell: ({ row }) => formatDate(row.endDate),
    },
    {
      field: "status",
      headerName: "Status",
      headerClassName: "vecto-column-header",
      flex: 1,
      renderCell: ({ row }) => {
        const { status } = row;
        const { label, className } = lookupVoucherStatus(status);
        return <span className={className}>{label || status}</span>;
      },
      sortComparator: (a, b) => {
        const order = { EXPIRED: 0, PENDING: 1, ACTIVE: 2 };
        return order[a] - order[b];
      },
    },
  ];

  return (
    <>
      <ViewVoucherModal
        user={user}
        voucher={voucher}
        setVoucher={setVoucher}
        getVouchers={getVouchers}
        setVouchers={setVouchers}
        open={viewModalOpen}
        setOpen={setViewModalOpen}
      />

      <CreateVoucherModal
        open={createModalOpen}
        setCreateModalOpen={setCreateModalOpen}
        getVouchers={getVouchers}
        setVouchers={setVouchers}
        user={user}
      />

      <TableTopper>
        <PageHeader>Vouchers</PageHeader>
        <SearchInput value={filterText} setFilterText={setFilterText} />
        <CreateButton setCreateModalOpen={setCreateModalOpen}>Create Voucher</CreateButton>
      </TableTopper>

      <Table
        data={displayData}
        columns={columns}
        setTarget={setVoucher}
        setViewModalOpen={setViewModalOpen}
        defaultSort={[{ field: "endDate", sort: "desc" }]}
      />
    </>
  );
};

export default VoucherTable;
