import React, { useState, useCallback } from "react";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Input from "@mui/material/Input";
import { Typography, Modal, Card } from "@mui/material";
import Button from "@mui/material/Button";
import { API, Auth } from "aws-amplify";
import { v4 as uuid } from "uuid";

async function getApiAuthHeaders() {
  const session = await Auth.currentSession();
  const accessToken = session.getAccessToken();
  const jwtToken = accessToken.getJwtToken();
  const Authorization = `Bearer ${jwtToken}`;
  return { Authorization };
}

const AddCompanyModal = ({ open, setOpen, getCompanies, setCompanies }) => {
  const [companyName, setCompanyName] = useState("");
  const [address, setAddress] = useState("");
  const [address2, setAddress2] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postalCode, setPostalCode] = useState("");

  const saveCompany = useCallback(async () => {
    const headers = await getApiAuthHeaders();

    let fullAddress = address;
    if (address2) fullAddress += `, ${address2}`;

    const body = {
      name: companyName,
      address: fullAddress,
      city,
      state,
      postalCode,
    };

    const newId = uuid();

    const { data } = await API.put("vectoapi", `/api/companies/${newId}`, { headers, body });
    return data;
  }, [address, address2, companyName, city, state, postalCode]);

  const CompanyNameInput = (
    <FormControl style={{ marginTop: 10 }}>
      <InputLabel htmlFor="companyName">Company Name</InputLabel>
      <Input id="companyName" onChange={(e) => setCompanyName(e.target.value)} />
    </FormControl>
  );

  const AddressInput = (
    <FormControl style={{ marginTop: 10 }}>
      <InputLabel htmlFor="address">Address</InputLabel>
      <Input id="address" onChange={(e) => setAddress(e.target.value)} />
    </FormControl>
  );

  const Address2Input = (
    <FormControl style={{ marginTop: 10 }}>
      <InputLabel htmlFor="address2">Address 2(Apt, suite, building, floor, etc.)</InputLabel>
      <Input id="address2" onChange={(e) => setAddress2(e.target.value)} />
    </FormControl>
  );

  const CityInput = (
    <FormControl style={{ marginTop: 10 }}>
      <InputLabel htmlFor="city">City</InputLabel>
      <Input id="city" onChange={(e) => setCity(e.target.value)} />
    </FormControl>
  );

  const StateInput = (
    <FormControl style={{ marginTop: 10 }}>
      <InputLabel htmlFor="state">State</InputLabel>
      <Input id="state" onChange={(e) => setState(e.target.value)} />
    </FormControl>
  );

  const PostalCodeInput = (
    <FormControl style={{ marginTop: 10 }}>
      <InputLabel htmlFor="postalCode">Postal Code</InputLabel>
      <Input id="postalCode" onChange={(e) => setPostalCode(e.target.value)} />
    </FormControl>
  );

  const SubmitButton = (
    <Button
      variant="outlined"
      style={{ marginTop: 50 }}
      onClick={async () => {
        await saveCompany();
        const companies = await getCompanies();
        setCompanies(companies);
        setOpen(false);
      }}
    >
      SUBMIT
    </Button>
  );

  return (
    <Modal open={open} onClose={() => setOpen(false)}>
      <Card
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          border: "2px solid #000",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography>ADD COMPANY</Typography>
        {CompanyNameInput}
        {AddressInput}
        {Address2Input}
        {CityInput}
        {StateInput}
        {PostalCodeInput}
        <br />
        {SubmitButton}
      </Card>
    </Modal>
  );
};

export default AddCompanyModal;
