import { useState, useEffect, useCallback } from "react";

import { API } from "aws-amplify";
import { Modal, Grid, Card, Button, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import TextField from "@mui/material/TextField";
import { v4 as uuid } from "uuid";

import { VOUCHER_TYPES } from "../../config";
import { PROVIDERS } from "../../config";

import { getApiAuthHeaders, isUserAdmin, getAdjustersByCompany, getCompanies, getAdjuster } from "../../utils";

import MessageModal from "../MessageModal";

const CreateVoucherModal = (props) => {
  const { open, user, setCreateModalOpen, getVouchers, setVouchers } = props;
  const { username } = user;

  const [isAdmin, setAdmin] = useState(false);
  const [isFormComplete, setFormComplete] = useState(false);
  const [messageModalOpen, setMessageModalOpen] = useState(false);
  const [messageTitle, setMessageTitle] = useState("");
  const [messageContent, setMessageContent] = useState("");

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [claimId, setClaimId] = useState("");
  const [adjusterFullName, setAdjusterFullName] = useState("");
  const [companyId, setCompanyId] = useState("");
  const [adjusterUsername, setAdjusterUsername] = useState("");
  const [provider, setProvider] = useState(null);
  const [type, setType] = useState("");
  const [amount, setAmount] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [companies, setCompanies] = useState([]);
  const [adjusters, setAdjusters] = useState([]);

  const resetVoucher = useCallback(() => {
    setFirstName("");
    setLastName("");
    setEmail("");
    setPhone("");
    setClaimId("");
    setAdjusterFullName("");
    setCompanyId("");
    setAdjusterUsername("");
    setProvider("");
    setType("");
    setAmount("");
    setStartDate(null);
    setEndDate(null);
  }, [
    setFirstName,
    setLastName,
    setEmail,
    setPhone,
    setClaimId,
    setAdjusterFullName,
    setCompanyId,
    setAdjusterUsername,
    setProvider,
    setType,
    setAmount,
    setStartDate,
    setEndDate,
  ]);

  const createVoucher = useCallback(async () => {
    const headers = await getApiAuthHeaders();
    const body = {
      status: "PENDING",
      claimId,
      companyId,
      adjusterUsername,
      firstName,
      lastName,
      email,
      phone,
      adjusterFullName,
      provider,
      type,
      amount,
      startDate,
      endDate,
    };
    const newId = uuid();
    const response = await API.put("vectoapi", `/api/vouchers/${newId}`, { headers, body });
    const { data } = response;
    return data;
  }, [
    claimId,
    companyId,
    adjusterUsername,
    firstName,
    lastName,
    email,
    phone,
    adjusterFullName,
    startDate,
    endDate,
    amount,
    provider,
    type,
  ]);

  useEffect(() => {
    isUserAdmin().then((userIsAdmin) => {
      setAdmin(userIsAdmin);
      if (userIsAdmin) {
        getCompanies().then(setCompanies);
        if (companyId) {
          getAdjustersByCompany(companyId).then(setAdjusters);
        }
      } else {
        setAdjusterUsername(username);
        getAdjuster(username).then(({ companyId }) => setCompanyId(companyId));
      }
    });
  }, [companyId, username]);

  useEffect(() => {
    const allFieldsTrue = [
      firstName,
      lastName,
      email,
      phone,
      claimId,
      adjusterFullName,
      companyId,
      adjusterUsername,
      provider,
      type,
      amount,
      startDate,
      endDate,
    ].every((field) => field);

    setFormComplete(allFieldsTrue);
  }, [
    setFormComplete,
    firstName,
    lastName,
    email,
    phone,
    claimId,
    adjusterFullName,
    companyId,
    adjusterUsername,
    provider,
    type,
    amount,
    startDate,
    endDate,
  ]);

  const CancelButton = (
    <Button
      color="error"
      onClick={() => {
        setCreateModalOpen(false);
        resetVoucher();
      }}
      className="voucher-create-action"
    >
      CANCEL
    </Button>
  );

  const displaySuccessMessage = useCallback(() => {
    const title = "Created Voucher";
    const name = [firstName, lastName].join(" ").trim();
    const message = [
      `You have successfully created the voucher for ${name}!`,
      <br />,
      `Please check back in to check the voucher's status.`,
    ];
    setMessageTitle(title);
    setMessageContent(message);
    setMessageModalOpen(true);
  }, [setMessageTitle, setMessageContent, setMessageModalOpen, firstName, lastName]);

  const updateVouchersTable = useCallback(async () => {
    const vouchers = await getVouchers();
    setVouchers(vouchers);
  }, [getVouchers, setVouchers]);

  const submitNewVoucherHandler = useCallback(async () => {
    await createVoucher();
    await updateVouchersTable();
    displaySuccessMessage();
    setCreateModalOpen(false);
    resetVoucher();
  }, [createVoucher, updateVouchersTable, displaySuccessMessage, setCreateModalOpen, resetVoucher]);

  const SubmitButton = (
    <Button
      variant="contained"
      disabled={!isFormComplete}
      onClick={submitNewVoucherHandler}
      className="voucher-create-action"
    >
      SUBMIT
    </Button>
  );

  const FirstNameInput = (
    <FormControl fullWidth>
      <TextField
        value={firstName}
        onChange={(e) => setFirstName(e.target.value)}
        id="firstName"
        label="First Name"
        variant="standard"
      />
    </FormControl>
  );

  const LastNameInput = (
    <FormControl fullWidth>
      <TextField
        value={lastName}
        onChange={(e) => setLastName(e.target.value)}
        id="lastName"
        label="Last Name"
        variant="standard"
      />
    </FormControl>
  );

  const EmailInput = (
    <FormControl fullWidth>
      <TextField value={email} onChange={(e) => setEmail(e.target.value)} id="email" label="Email" variant="standard" />
    </FormControl>
  );

  const PhoneInput = (
    <FormControl fullWidth>
      <TextField value={phone} onChange={(e) => setPhone(e.target.value)} id="phone" label="Phone" variant="standard" />
    </FormControl>
  );

  const AdjusterFullNameInput = (
    <FormControl fullWidth>
      <TextField
        value={adjusterFullName}
        onChange={(e) => setAdjusterFullName(e.target.value)}
        id="adjusterFullName"
        label="Adjuster"
        variant="standard"
      />
    </FormControl>
  );

  const ClaimInput = (
    <FormControl fullWidth>
      <TextField
        value={claimId}
        onChange={(e) => setClaimId(e.target.value)}
        id="claimId"
        label="Claim Number"
        variant="standard"
      />
    </FormControl>
  );

  const StartInput = (
    <FormControl fullWidth>
      <DatePicker
        label="Start Date"
        value={startDate}
        onChange={(value) => setStartDate(value)}
        variant="standard"
        renderInput={(params) => <TextField variant="standard" {...params} />}
      />
    </FormControl>
  );

  const EndInput = (
    <FormControl fullWidth>
      <DatePicker
        label="End Date"
        value={endDate}
        onChange={(value) => setEndDate(value)}
        renderInput={(params) => <TextField variant="standard" {...params} />}
      />
    </FormControl>
  );

  const CompanySelect = (
    <FormControl fullWidth>
      <InputLabel id="company-label">Company</InputLabel>
      <Select
        labelId="company-label"
        id="companyId"
        value={companyId}
        label="Company"
        variant="standard"
        onChange={(e) => setCompanyId(e.target.value)}
      >
        {companies.map((company) => {
          return (
            <MenuItem key={company.id} value={company.id}>
              {company.name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const AdjusterSelect = (
    <FormControl fullWidth>
      <InputLabel id="adjuster-label">Adjuster</InputLabel>
      <Select
        labelId="adjuster-label"
        id="adjusterUsername"
        value={adjusterUsername}
        label="Adjuster"
        variant="standard"
        onChange={(e) => setAdjusterUsername(e.target.value)}
      >
        {adjusters.map((adjuster) => {
          return (
            <MenuItem key={adjuster.username} value={adjuster.username}>
              {adjuster.username}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const ProviderSelect = (
    <FormControl fullWidth>
      <InputLabel style={{ marginLeft: -14 }} id="provider-label">
        Provider
      </InputLabel>
      <Select
        labelId="provider-label"
        id="provider"
        value={provider}
        label="Provider"
        variant="standard"
        onChange={(e) => setProvider(e.target.value)}
      >
        {PROVIDERS.map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const TypeSelect = (
    <FormControl fullWidth>
      <InputLabel style={{ marginLeft: -14 }} id="type-label">
        Voucher Type
      </InputLabel>
      <Select
        labelId="type-label"
        id="type"
        value={type}
        label="Voucher Type"
        variant="standard"
        onChange={(e) => setType(e.target.value)}
      >
        {VOUCHER_TYPES.map(({ value, label }) => {
          return (
            <MenuItem key={value} value={value}>
              {label}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );

  const AmountInput = (
    <FormControl fullWidth>
      <TextField
        value={amount}
        onChange={(e) => setAmount(+e.target.value)}
        id="amount"
        label="Voucher Amount"
        variant="standard"
      />
    </FormControl>
  );

  return (
    <>
      <MessageModal
        title={messageTitle}
        message={messageContent}
        open={messageModalOpen}
        setOpen={setMessageModalOpen}
      />

      <Modal
        open={open}
        onClose={() => {
          setCreateModalOpen(false);
          resetVoucher();
        }}
      >
        <Card className="voucher-create-card">
          <Grid container spacing={1}>
            <h2 className="voucher-create-card-header">{isAdmin ? "Admin Create Voucher" : "Create Voucher"}</h2>
            <Grid item xs={12}>
              <Grid container spacing={5}>
                {isAdmin && (
                  <Grid item xs={12}>
                    {CompanySelect}
                  </Grid>
                )}

                {isAdmin && companyId && (
                  <Grid item xs={12}>
                    {AdjusterSelect}
                  </Grid>
                )}

                <Grid item xs={12}>
                  <Grid container spacing={5}>
                    <Grid item xs={6}>
                      {ClaimInput}
                    </Grid>
                    <Grid item xs={6}>
                      {AdjusterFullNameInput}
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className="voucher-create-card-sub-header">Client Information</div>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  {FirstNameInput}
                </Grid>
                <Grid item xs={6}>
                  {LastNameInput}
                </Grid>
              </Grid>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  {EmailInput}
                </Grid>
                <Grid item xs={6}>
                  {PhoneInput}
                </Grid>
              </Grid>
            </Grid>

            <Grid item xs={12}>
              <div className="voucher-create-card-sub-header">Voucher Information</div>
              <Grid container spacing={5}>
                <Grid item xs={6}>
                  {ProviderSelect}
                </Grid>
                <Grid item xs={6}>
                  {TypeSelect}
                </Grid>
                <Grid item xs={6}>
                  {AmountInput}
                </Grid>
                <Grid item xs={6}></Grid>
                <Grid item xs={6}>
                  {StartInput}
                </Grid>
                <Grid item xs={6}>
                  {EndInput}
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} className="voucher-create-action-button-row">
              {CancelButton}
              {SubmitButton}
            </Grid>
          </Grid>
        </Card>
      </Modal>
    </>
  );
};

export default CreateVoucherModal;
