import { useState } from "react";
import { Card } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";

const Table = (props) => {
  const { data, columns, setTarget, setViewModalOpen, defaultSort: sortModel } = props;

  const [selectionModel, setSelectionModel] = useState([]);

  const sorting = { sortModel };
  return (
    <Card className="table-wrapper">
      <DataGrid
        initialState={{ sorting }}
        className="data-table"
        disableColumnMenu
        disableColumnFilter
        autoPageSize
        selectionModel={selectionModel}
        onSelectionModelChange={(selection) => {
          const [targetId] = selection;
          const record = data.find(({ id }) => id === targetId) || {};
          setTarget(record);
          setViewModalOpen(true);
          setSelectionModel([]);
        }}
        rows={data}
        columns={columns}
        rowHeight={60}
      />
    </Card>
  );
};

export default Table;
