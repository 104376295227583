import { Button } from "@mui/material";

const ViewCompanyButton = ({ company, setTargetCompany, setViewCompanyOpen }) => {
  return (
    <Button
      variant="contained"
      color="primary"
      size="small"
      style={{ marginLeft: 16 }}
      onClick={() => {
        setTargetCompany(company);
        setViewCompanyOpen(true);
      }}
    >
      View
    </Button>
  );
};

export default ViewCompanyButton;
