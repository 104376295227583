import { Button } from "@mui/material";

const LogoutButton = (props) => {
  const { signOut } = props;

  return (
    <Button id="logout" onClick={signOut}>
      Logout
    </Button>
  );
};

export default LogoutButton;
