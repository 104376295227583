import { Button } from "@mui/material";

const CreateButton = (props) => {
  const { children, setCreateModalOpen } = props;
  return (
    <Button className="create-button" variant="contained" onClick={() => setCreateModalOpen(true)}>
      {children}
    </Button>
  );
};

export default CreateButton;
