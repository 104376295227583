const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": process.env.REACT_APP_COGNITO_POOL_ID, //"us-east-1_dvsMneO6D",
    "aws_user_pools_web_client_id": process.env.REACT_APP_COGNITO_WEB_CLIENT_ID, // "1g9p3rq43tdgps90bog8dpkrp4",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_cloud_logic_custom": [
        {
            "name": "vectoapi",
            "endpoint": process.env.REACT_APP_RIDESHARE_API_URL, //"https://s63ee1ttal.execute-api.us-east-1.amazonaws.com/dev",
            "region": process.env.REACT_APP_AWS_REGION //"us-east-1"
        }
    ]
};

export default awsmobile;