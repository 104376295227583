import { Typography, Toolbar } from "@mui/material";

import { formatDate } from "../../utils";

const Greeting = (props) => {
  const { displayName } = props;
  return (
    <Toolbar className="greeting-wrapper">
      <Typography className="greeting">Hello {displayName}</Typography>
      <Typography className="greeting" style={{ textAlign: "left" }}>
        {formatDate(new Date())}
      </Typography>
    </Toolbar>
  );
};

export default Greeting;
