const DEFAULT_DATE_FORMAT = "MM/DD/YYYY";
const VOUCHER_TYPES = [
  { value: "PER_DAY", label: "Per Day" },
  { value: "PER_TRANSACTION", label: "Per Transaction" },
  { value: "GIFT_CARD", label: "Gift Card" },
];
const VOUCHER_STATUSES = [
  { value: "PENDING", label: "Pending", className: "pending-status" },
  { value: "ACTIVE", label: "Active", className: "active-status" },
  { value: "EXPIRED", label: "Expired", className: "expired-status" },
];
const PROVIDERS = [
  { label: "Uber", value: "UBER" },
  { label: "Lyft", value: "LYFT" },
];

export { DEFAULT_DATE_FORMAT, VOUCHER_TYPES, VOUCHER_STATUSES, PROVIDERS };
