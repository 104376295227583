import { AppBar, Toolbar } from "@mui/material";

const TableTopper = (props) => {
  const { children } = props;

  return (
    <AppBar className="table-topper-wrapper" position="static">
      <Toolbar className="table-topper">{children}</Toolbar>
    </AppBar>
  );
};

export default TableTopper;
