import { Typography } from "@mui/material";

const PageHeader = (props) => {
  const { children } = props;

  return (
    <Typography variant="h4" className="page-header">
      {children}
    </Typography>
  );
};

export default PageHeader;
